import Home from "./home";
import Cartao from "./cartao";
import SelecaoParcelas from "./selecaoParcelas";
import NovoPagamento from "./novoPagamento";
import SolicitacaoSenha from "./solicitacaoSenha";
import Contrato from "./contrato";
import Cliente from "./cliente";
import MeuBonus from "./meuBonus";
import Pagamentos from "./pagamentos";

export default [
  {
    path: "/",
    redirect: "/home",
    component: () => import("@/layouts/default"),
    meta: {
      auth: true,
    },
    children: [
      ...Home,
      ...Pagamentos,
      ...Cartao,
      ...SelecaoParcelas,
      ...NovoPagamento,
      ...MeuBonus,
      ...SolicitacaoSenha,
      ...Contrato,
      ...Cliente,
    ],
  },
  {
    path: "/login",
    name: "Acesso",
    component: () => import("@/views/Login"),
    hidden: true,
    meta: {
      auth: false,
      title: "Login",
    },
  },
  {
    path: "/contrato-adesao",
    name: "Contrato Adesão",
    component: () => import("@/views/Contrato"),
    hidden: true,
    meta: {
      auth: false,
      title: "Contrato de adesão",
    },
  },
  {
    path: "/termo-adesao/:id_proposta",
    name: "Termo de Adesão",
    component: () => import("@/views/TermoAdesao"),
    hidden: true,
    meta: {
      auth: false,
      title: "Termo de adesão",
    },
  },
  {
    path: "/cartao-digital/:id_cartao",
    name: "Cartão Digital",
    component: () => import("@/views/CartaoDigital"),
    hidden: true,
    meta: {
      auth: false,
      title: "Cartão Digital",
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "Página de erro",
    component: () => import("@/views/404"),
    hidden: true,
    meta: {
      auth: false,
      title: "Erro 404",
    },
  },
];
