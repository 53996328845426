import store from "@/store/index";
import login from "@/plugins/login.js";

export default {
  data() {
    return {
      timerMixin: null,
      parametro: Number(process.env.VUE_APP_TEMPO_INATIVIDADE) * 1000,
      usuarioInativo: false,
    };
  },

  methods: {
    resetTimer() {
      if (this.usuarioInativo) {
        this.usuarioInativo = true;
        this.removeMonitor();
      } else {
        this.usuarioInativo = false;
        clearTimeout(this.timerMixin);
        if (this.parametro > 0) {
          this.timerMixin = setTimeout(this.$validarUsuario, this.parametro, {
            usuarioInativo: false,
            isModal: false,
            chamaReset: false,
          });
        }
      }
    },

    monitorInatividade() {
      this.$validarUsuario({
        usuarioInativo: false,
        isModal: false,
        chamaReset: true,
        inicio: true,
      });

      if (store.getters.getModoTotem) {
        window.addEventListener("onload", this.resetTimer);
        window.addEventListener("click", this.resetTimer);
        window.addEventListener("keydown", this.resetTimer);
      }
    },

    removeMonitor() {
      window.removeEventListener("onload", this.resetTimer, false);
      window.removeEventListener("click", this.resetTimer, false);
      window.removeEventListener("keydown", this.resetTimer, false);
    },

    $logout() {
      this.removeMonitor();
      login.logout();
    },

    $validarUsuario({ usuarioInativo, isModal, chamaReset, inicio = false }) {
      if (usuarioInativo) {
        this.$logout();
      } else {
        if (isModal) {
          if (chamaReset) {
            this.resetTimer();
          }
        } else {
          if (!inicio) {
            try {
              this.$refs.modalUsuarioInativo.open();
            } catch (error) {
              //pass
            }
          }
          if (chamaReset) {
            this.resetTimer();
          }
        }
      }
    },
  },
}; 
